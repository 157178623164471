//  WARNING: DON'T CHANGE THIS FILE MANUALLY
//  This file has been generated by the builder tool.
//  All changes you manually make to this file will be reverted on the next build
$(function () {



//  -- site-config Starts --
AOS.init();


window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'G-3BTSSFLQYT');

//  -- site-config Ends --




//  -- templates/home Starts --

//  -- templates/home Ends --




//  -- templates/simple-page Starts --

//  -- templates/simple-page Ends --


});
